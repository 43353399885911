.ratio {
  position: relative;
  --aspect-ratio: 100%;

  &:before{
    display: block;
    padding-top: var(--aspect-ratio);
    content: "";
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@each $suffix, $brpoint in $media-breakpoints {
  @include media-breakpoint-up($suffix) {
    .ratio.#{$suffix}-ratio {
      &:before {
        padding-top: var(--#{$suffix}-aspect-ratio, --aspect-ratio);
      }
    }
  }
}