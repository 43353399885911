.mobile-menu {

  position: fixed;
  visibility: hidden;
  top: var(--header-height, 0);
  bottom: 0;
  width: 90vw;
  left: -100vw;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  z-index: 1000;
  box-shadow: $boxshadow-hover;

  display: flex;
  flex-direction: column;

  .mobile-menu__close {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    text-align: right;
    padding: $space-xs;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .mobile-menu__nav {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    min-width: 1%;
    display: flex;
    border-top: 1px solid $color-border-strong;
  }

  .mobile-menu__nav-base, .mobile-menu__nav-next {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    transition: all 0.2s ease-in-out;
  }

  .mobile-menu__link {
    display: flex;
    width: 100%;
    align-items: center;
    padding: $space-m;
    border-bottom: 1px solid $color-border;
    font-size: $fontsize-s;
    span {
      display: block;
      flex-grow: 1;
    }
    svg {
      display: block;
      width: 16px;
      height: 16px;
      color: $color-text-extralight;
    }
  }

  .mobile-menu__nav-base .mobile-menu__nav-next {
    display: none;
  }

  .mobile-menu__nav > .mobile-menu__nav-next {
    display: flex !important;
    flex-direction: column;
    height: 100%;

    .mobile-menu__nav-back {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-border-strong;
      background-color: $color-surface-highlight;
      font-size: $fontsize-s;
      .mobile-menu__nav-back-btn {
        display: block;
        padding: $space-s;

        svg {
          display: block;
          width: 24px;
          height: 24px;
        }
      }

    }

    .mobile-menu__nav-links {
      flex-grow: 1;
      overflow: auto;
    }
  }
}

.mobile-menu-opened {
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 500;
  }
}

.mobile-menu.c-menu-opened {
  left: 0;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}