.nav-drop {
  position: relative;

  &__drop {
    z-index: 500;
    visibility: hidden;
    display: none;
    position: absolute;
    min-width: 100%;
  }

  &:hover {
    .nav-drop__drop {
      display: block;
      visibility: visible
    }
  }
}