.bpl {

  &__header {
    margin-bottom: $space-m;
  }

  &__footer {
    margin-top: $space-l;
    border-top: 1px solid $color-border;
    padding-top: $space-l;
  }

}