

.product-box-list.product-box-list--grid {
  @include media-breakpoint-up('md') {
    --columns: 3;
  }

  @include media-breakpoint-down('md') {
    --columns: 2 !important;
  }

  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  display: grid;
  width: 100%;

  gap: $space-row-gap;
  row-gap: $space-row-gap;
}

@import "grid-product-box";