.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  .brbs {
    margin-top: 0;
  }

  &__t {
    font-weight: $fontweight-bold;
    font-size: $fontsize-xl;
    margin-top: $space-s;
    position: relative;
    margin-bottom: $space-m;
    padding-bottom: .75rem;
    border-bottom: 2px solid #e9f0f1;
  }

  &__tl{
    margin-top: 2.5rem;
    margin-bottom: $space-l;
    font-size: $fontsize-m;
  }

}