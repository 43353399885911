@mixin header--logo {
  img {
    max-height: 45px;
    width: auto;
    max-width: 40vw;
    height: auto;
  }

  @include media-breakpoint-up('md') {
    img {
      height: 45px;
      width: auto;
    }
  }
}

@mixin header--main {
  @include media-breakpoint-up(lg) {
    padding-top: $space-m;
    padding-bottom: $space-m;
    border-bottom: 0;
  }

  padding-top: $space-xs;
  padding-bottom: $space-xs;
  border-bottom: 1px solid $color-border;
}

.header {
  position: relative;
  z-index: 1000;
  background-color: #fff;

  &__main {
    @include header--main();
  }

  &__logo {
    @include header--logo();
  }

  &__actions {

  }

  .mobile-menu-button {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

}

@import "parts/nav-drop";

@import "parts/header-top-bar";
@import "parts/header-nav";
@import "parts/mobile_nav";