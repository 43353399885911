.field-index {
  &__col {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__link {
    width: 100%;

    @include media-breakpoint-up(md){
      width: 50%;
    }

    @include media-breakpoint-up(lg){
      width: 20%;
    }

    display: block;
    margin-top: $space-m;
    &:hover {
      color: $color-accent;
    }
  }
}