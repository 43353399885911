
.p-text-block {
  font-size: 0.925rem;
  line-height: 1.4rem;

  i, em {
    font-style: italic;
  }

  b, strong {
    font-weight: 500;
  }

  * + p, * + ul, * + ol, * + h2, * + h3, * + h4, * + h5, * + h6, * + figure {
    margin-top: 0.8em;
  }

  ul {
    list-style: square;
    list-style-position: outside;
    margin-left: 1.5em;

  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 2em;
  }

  h2 {
    font-size: 1.6em;
    font-weight: 500;
  }

  h3 {
    font-size: 1.2em;
    font-weight: 500;
  }

  h4 {
    font-size: 1.1em;
    font-weight: 500;
  }

  a {
    color: $color-accent;
    text-decoration: underline;
    text-decoration-color: $color-text-extralight;

    &:hover {
      color: $color-accent-hover;
      text-decoration-color: $color-text-light;
    }
  }


  table {
    font-size: 0.925rem;
    border-collapse: collapse;
    border-top: 1px solid $color-border-strong;
    tr {
      border-left: 1px solid $color-border-strong;
      border-bottom: 1px solid $color-border-strong;
    }
    td,th {
      border-right: 1px solid $color-border-strong;
      padding: $space-xs $space-m;
    }

  }

}