$section--beetween-gap: $space-row-gap;

@mixin section {

  & + & {
    margin-top: $section--beetween-gap;
  }
}


@mixin section--title(){
  position: relative;

  font-size: $fontsize-xl;
  font-weight: $fontweight-bold;

  @include media-breakpoint-up('md') {
    font-size: $fontsize-xxl;
    font-weight: $fontweight-bold;
  }

  &--small {
    font-size: $fontsize-xl;
    font-weight: $fontweight-bold;
  }

  &-tagline{
    font-size: 0.75rem;
    font-weight: $fontweight-base;
    margin-bottom: .5rem;
    color: $color-accent;
  }

  @include media-breakpoint-up('md'){

  }

  &.section__title--with-tagline {

  }

}

.section {

  @include section();

  &__title {
    @include section--title();
  }
}


