.banner {
  background-repeat: no-repeat;

  display: flex;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    max-width: 50rem;

    padding: 3rem 0;
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-size: 1.125rem;
    line-height: 1.4;

    .banner__text {
      display: none;
    }

    p {
      margin-top: .5em;
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.25;
  }

}


@include media-breakpoint-up('md') {
  .banner {
    min-height: 640px;

    &__title {
      font-size: 3rem;
      font-weight: 800;
      line-height: 1.25;
    }

    &__text {
      .banner__text {
        display: block;
      }
    }
  }
}