select {
  --b-color: #{$color-border};

  background-image: linear-gradient(45deg, transparent 50%, var(--b-color) 50%), linear-gradient(135deg, var(--b-color) 50%, transparent 50%);

  background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;

  transition: all 0.2s ease-in-out;

  &:hover {
    --b-color: #{$color-border-strong};
  }
}
