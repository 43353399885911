$tax-drop--height: calc(100vh - 220px);

.tax-drop {

  &__drop {
    position: absolute;
    left:0;
    width: 0;
    background-color: #0b5cad;
    height: $tax-drop--height;
    display: none;
  }



  &__drop-container {

    display: flex;
    height: 100%;
  }

  &__roots {
    width: 33.333333%;
    overflow: auto;
    height: 100%;
  }

  &__sub {

  }



}