$paginator-height: #{$fontsize-m + $space-l};

.paginator-full, .paginator-short {
  &__btn {
    @include button-base('base', 'base');

    &:hover {
      background-color: $color-surface-highlight;
      color: $color-accent;

    }
  }

  &__btn-label {
    display: none;
  }
}



.paginator-short {
  display: flex;
  align-items: stretch;
  height: 100%;
  gap: $space-m;
  font-size: $fontsize-s;
  justify-content: flex-end;

  .paginator-short__pages {
    gap: 10px;
    display: flex;
    align-items: baseline;
    height: 100%;
  }

  .paginator-short__pages-input {
    -moz-appearance: textfield;
    height: 100%;
    width: 2.5rem;
    text-align: center;
    border: 1px solid $color-border;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }

  .paginator-short__pages-from, .paginator-short__pages-count {
    display: block;
    line-height: $paginator-height;
  }

}


.paginator-full {
  width: fit-content;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: $space-m;
  font-size: $fontsize-s;

  &.paginator-full--center {
    margin-left: auto;
    margin-right: auto;
  }

  .paginator-full__pages {
    display: flex;
    flex-wrap: nowrap;
    gap: $space-s;

    a, span {
      border: 1px solid $color-border-light;
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: $paginator-height;
    }

    .paginator-full__pages-sep {
      background-color: transparent;
      color: $color-text-light;
      border-color: transparent;
    }

    a:hover {
      background-color: $color-surface-highlight;
      color: $color-accent;
      border-color: $color-border-strong;
    }

    .paginator-full__page-current {
      border: 1px solid $color-border-strong;
      color: $color-text;
    }
  }

  .paginator-full__btn.paginator-full__btn-next {
    .paginator-full__btn-label {
      display: block;
    }
  }

}

