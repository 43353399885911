
@mixin nav-drop-full--drop {
  background-color: $color-surface;
}

@mixin nav-drop-full--drop-container {
  background-color: $color-surface;
  padding-top: $space-l;
  padding-bottom: $space-l;
}

@mixin nav-drop-full--col-link {
  font-weight: 400;
}

@mixin nav-drop-full--col {
  font-weight: 400;
  padding-bottom: $space-xl;
  padding-right: $space-l;
}

//import overwrites
@import "../../../style_mixins";

.nav-drop-full {

  &__drop {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;

    @include nav-drop-full--drop();

  }

  &:hover {
    & .nav-drop-full__drop {
      visibility: visible;
      height: auto;
      overflow: visible;
      pointer-events: none;
      opacity: 1;
      left: 0;
      right:0;
      width: 100%;
      z-index: 200;
    }
  }

  &__drop-container {
    display: flex;
    pointer-events: auto;
    @include nav-drop-full--drop-container();
  }

  //&__body {
  //  columns: 4;
  //}
  //
  //&__col {
  //  page-break-inside: avoid;
  //  break-inside: avoid;
  //  @include nav-drop-full--col();
  //}
  //
  //&__col-link {
  //  @include nav-drop-full--col-link();
  //}

}