@import "blocks/p-meta";
@import "blocks/p-short-desc";
@import "blocks/p-text-block";


.product-section {
  margin-top: $space-l;
}

.p-imgwrap {
  border: 1px solid $color-border;
  box-shadow: inset 0px 0px 50px 0px $color-border;
  //padding: $space-xl;
  width: max-content;
  margin-left: auto;
  margin-right: auto;

  img {
    display: block;
    max-height: 25vh;
  }
}

.p-name-sticky-col {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-top: 0.5rem;
  margin-bottom: 2rem;
  z-index: 2;
}


.product-header__title {
  padding-top: $space-s;
  padding-bottom: $space-xl;
  border-bottom: 2px solid $color-border;

  .content-header__h {
    margin-top: 0;
    flex-grow: 1;
    margin-bottom: $space-s;
  }

  .button {
    display: block;
    width: 100%;
  }
}

@include media-breakpoint-up('md'){

  .product-header__title {
    padding-top: $space-s;
    padding-bottom: $space-s;
    border-bottom: 2px solid $color-border;
    display: flex;
    align-items: center;

    .content-header__h {
      margin-top: 0;
      flex-grow: 1;
      margin-bottom: 0;
    }


    .button {
      display: inline-block;
      width: auto;
    }
  }


}


.p-name {

  h1 {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 400;
    color: #000;
    padding-bottom: $space-xl;
    border-bottom: 1px solid $color-border;
  }

  .p-name__btn {
    margin-top: $space-xs;
  }
}


@include media-breakpoint-up('md'){
  .p-imgwrap {
    width: 100%;
    img {
      max-height: none;
    }
  }
  .p-name {
    h1 {
      font-size: 1.8rem;
    }

    .p-name__btn {
      float: right;
      margin-top: -6px;
    }
  }
}


.sticky-sidebar {
  position: sticky;
  top: 2rem;
  align-self: flex-start;
}

.p-contact {
  border: 1px solid $color-border;
  padding: $space-xl;


  .p-contact__head {
    font-weight: 700;
    font-size: $fontsize-m;
  }

  .p-contact__text {
    font-weight: 400;
    color: $color-text-light;
    font-size: $fontsize-s;
    margin-top: $space-m;
    margin-bottom: $space-l;
  }

}

$tabs-offset: 70px;

.tabs {

  --offset: #{$tabs-offset};

  .tabs__content {
    margin-bottom: $space-l;
  }

  .tabs__links {
    margin-top: calc(-1 * var(--offset));
    padding-top: var(--offset);

    display: flex;
    font-size: $fontsize-m;
    gap: $space-xl;
    font-weight: 500;
    border-bottom: 1px solid $color-border;


    a {
      color: $color-text-light;
      display: block;
      padding: $space-m 0;
      position: relative;
      top: 1px;

      &.selected {
        color: $color-accent-hover;
        border-bottom: 2px solid $color-accent;
      }
    }
  }
}


.tabs__text + .tabs__text {
  h2 {
    margin-top: $space-xl;
  }
}

.tabs__text {
  margin-top: $space-m;

  h2 {
    padding-top: $space-xl;
    border-top: 1px solid $color-border;
    margin-bottom: $space-l;
    font-size: $fontsize-m;
    font-weight: 600;
  }
}

.p-params {
  border: 1px solid $color-border;
  padding: $space-xl;


  dt {
    font-size: $fontsize-xs;
    color: $color-text-light;
    margin-bottom: $space-xs;
  }

  dd {
    padding-bottom: $space-l;
    margin-bottom: $space-l;
    border-bottom: 1px solid $color-border;
  }

  dd:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}


.p-files {
  margin-top: $space-xl;

  .p-files__head {
    font-weight: 700;
    font-size: $fontsize-m;
    border-bottom: 1px solid $color-border;
    margin-bottom: $space-l;

    span {
      border-bottom: 2px solid $color-accent;
      padding-bottom: $space-m;
      top: 1px;
      display: inline-block;
      position: relative;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: $space-m 0;

    svg {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 10px;
      margin-bottom: 4px;
    }

    span {
      display: block;
    }
  }

}


.p-cats {
  margin-top: $space-xl;

  .p-cats__head {
    font-weight: 700;
    font-size: $fontsize-m;
    border-bottom: 1px solid $color-border;
    margin-bottom: $space-l;

    span {
      border-bottom: 2px solid $color-accent;
      padding-bottom: $space-m;
      top: 1px;
      display: inline-block;
      position: relative;
    }
  }

  .p-cats__tax-section {
    & + .p-cats__tax-section {
      margin-top: $space-m;
      border-top: 1px solid $color-border;
      padding-top: $space-m;
    }

    &>div {
      margin-bottom: $space-xs;
    }
  }

  .p-cats__tax-head {
    font-size: $fontsize-base;
    font-weight: 500;
    padding: $space-xs 0;
  }

  a {
    font-size: $fontsize-s;
    color: $color-accent;

    &:hover {
      color: $color-accent-hover;
    }
  }

}
