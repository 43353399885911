svg.loader {
  height: 1rem;
  width: auto;
}

.with-loader {
  position: relative;

  .loader {
    display: none;
  }

  .loading-overlay {
    display: none;
  }

  &.loading {
    .loader {
      position: absolute;
      top: 20px;
      width: 60px;
      left: calc(50% - 30px);
      display: block;
    }

    .loading-overlay {
      background-color: rgba(255, 255, 255, .7);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
    }
  }
}