/*! StackPeak - Catalog - Layout v1.0  */
@use "sass:math";
@import "internal/functions";


$bootstrap--row-gap--space: $space-row-gap;
$bootstrap--container-horizonal-padd--space: math.div($space-row-gap, 2);

$media-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;

//ASSERTS VARIABLES
@include _assert-ascending($media-breakpoints, "$media-breakpoints");
@include _assert-starts-at-zero($media-breakpoints, "$media-breakpoints");

@import "mixins/media-breakpoints";
@import "internal/generate-utils";

@import "partials/containers";
@import "partials/rows";
@import "partials/utils";

