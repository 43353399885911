.blog-article-list {
  margin-bottom: 16px;
}

.blog-art-box {
  display: block;
  border: 1px solid $color-border;
  padding-bottom: $space-m;
  width: 100%;
  height: 100%;

  .blog-art-box__img {
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__b {
    display: flex;
    flex-direction: column;
    gap: $space-s;
  }

  &__btn {
    padding: $space-m $space-m 0 $space-m;
    text-align: right;
    a {
      color: #29abe2;
      padding: 8px;
    }
  }

  .blog-art-box__title {
    font-size: $fontsize-l;
    font-weight: 500;
    padding: $space-m $space-m 0 $space-m;
    line-height: 1.4em;
  }

  .blog-art-box__date {
    font-size: $fontsize-xs;
    color: $color-text;
    padding: 0 $space-m;
  }

  .blog-art-box__lead {
    font-size: $fontsize-s;
    line-height: $lineheight-xl;
    padding: 0 $space-m 0 $space-m;
  }

  &:hover {
    border: 1px solid $color-accent-hover;
  }

  &--small {
    .blog-art-box__title {
      font-size: $fontsize-s;
      padding: $space-s;
    }
    .blog-art-box__date {
      font-size: $fontsize-xxs;
      color: $color-text;
      padding: 0 $space-s;
    }
  }
}