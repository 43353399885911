.main-banner {
  padding-top: $space-xxl;
  padding-bottom: $space-xxl;

  .main-banner__c {
    position: relative;
  }

  .main-banner__text {
    position: absolute;
    top: 20%;
    left: 2.5%;
    width: 50%;
  }

  .main-banner__text-h {
    font-size: $fontsize-xl;
    font-weight: 500;
    margin-bottom: 3%;
    color: $color-accent-hover;
  }

  .main-banner__text-c {
    font-size: $fontsize-m;
    margin-bottom: 3%;
  }

  .main-banner__text-btn {

  }
}


@include media-breakpoint-up('md') {
  .main-banner {
    .main-banner__text {
      top: 30%;
      left: 2.5%;
      width: 60%;

      .main-banner__text-h {
        font-size: $fontsize-xxxl;
        font-weight: 500;
        margin-bottom: 3%;
        color: $color-accent-hover;
      }

      .main-banner__text-c {
        font-size: $fontsize-l;
      }
    }
  }
}