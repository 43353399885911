.banner {
  background-repeat: no-repeat;
  min-height: 540px;
  display: flex;
  align-items: flex-end;

  &__content {
    margin: 4rem;
    padding: 3rem;
    background: #0890ccc4;
    color: #fff;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &__title {
    font-size: 3rem;
    font-weight: 500;
  }

  &__text {
    font-size: 1.5rem;
  }
}