$color--label: $color-text-light;
$color-border--form-input: $color-border-strong;
$color-border--form-input-focus: $color-accent;

$color--error: #cb0000;

.app-form {
  .thank-you.visible {
    display: block !important;
  }

  .form-data.hidden {
    display: none !important;
  }

  .app-form__loading {
    display: none;
  }

  &.is-loading {
    position: relative;
    overflow: hidden;

    .app-form__loading {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.4);
      display: block;

      svg {
        display: block;
        margin: 2rem auto;
      }
    }
  }

  .thank-you {
    margin: $space-m;
    text-align: center;
    padding: $space-l $space-l;
    background-color: $color-text-light;

    .thank-you-title {
      font-size: $fontsize-l;
      font-weight: 600;
      margin-bottom: $space-m;
    }

    p {
      padding: 0 $space-xl;
      line-height: 1.5em;
    }
  }

}


.app-form .form-data {

  button:focus {
    outline: 0;
  }

  .field {
    display: flex;
    flex-direction: column;

    ul {
      order: 100;
    }
  }

  label {
    display: block;
    color: $color--label;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 1.15em;

    &.required {
      &::after {
        content: '*';
        display: inline-block;
        color: $color--label;
        margin-left: 5px;
      }
    }

    &.checkbox-label {
      cursor: pointer;
    }
  }


  input[type="checkbox"], input[type="radio"] {
    display: inline-block !important;
    appearance: auto;
    width: auto;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="color"],
  input[type="date"],
  input[type="datetime-local"],
  textarea,
  select {
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    padding: 8px 6px;
    border: 1px solid $color-border--form-input;
    outline: 0;

    &:focus {
      border: 1px solid $color-border--form-input-focus;
    }
  }

  textarea {
    height: 90px;
  }

  &.errors {
    input, textarea, select {
      border: 1px solid $color--error;
    }
  }


  .disclaimers {
    margin-top: 20px;
  }

  .send-section {
    margin-top: 20px;
  }

  .discl-section {
    font-size: $fontsize-xs;
    color: $color-text-extralight;

    p + p {
      margin-top: .5rem;
    }
  }

  .custom-check {

    .catname {
      margin-left: 5px;
      color: $color--label;
    }

    &.bold {
      .catname {
        font-weight: 500;
        color: $color--label;
      }
    }

    &.disclaimer {
      .catname {
        font-size: .85rem;
        text-align: justify;
        line-height: 1.4rem;
        font-weight: 400;
        margin-top: -2px;
        cursor: default;
      }
    }
  }

  .sample-hidden {
    display: none;
  }

  label + ul {
    list-style: none;
    padding: 0;
    display: block;
    margin-top: 5px;
    color: $color--error;
    margin-bottom: 0;
    font-size: $fontsize-s;
  }

  ul.errors {
    list-style: none;
    padding: 0;
    display: block;
    margin-top: 5px;
    margin-left: 5px;
    color: $color--error;
    margin-bottom: 0;
    font-size: $fontsize-s;
  }

  .custom-check {
    ul.errors {
      margin-left: 30px;
    }
  }

  .bottom-disclaimers {
    margin-top: 20px;

    .disclaimer {

      font-size: 0.85rem;
      text-align: justify;
      line-height: 1.6rem;
      color: $color--label;
      font-weight: 400;

      ul, ol {
        padding-left: 30px;
      }
    }
  }

  .more-bottom-disclaimers {
    text-align: right;
    padding-right: 5px;
    margin-bottom: 5px;

    a {
      display: inline-block;
      color: $color--label;
      font-size: 0.85rem;
      font-weight: 400;

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: inline-block;
        margin: 0 0px 0 8px;;
        border-bottom: 1px solid $color-border;
        border-right: 1px solid $color-border;

        transform: rotate(45deg);
        opacity: 0.5;
        vertical-align: 3px;
        transition: all 0.3s ease-in-out;
      }

      &.expanded-state {
        &:after {
          transform: rotate(225deg);
          vertical-align: 0px;
        }
      }

    }
  }

}