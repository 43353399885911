@use "sass:math";

$utilities: (
        "display": (
                responsive: true,
                print: true,
                property: display,
                class: d,
                values: inline inline-block block grid table table-row table-cell flex inline-flex none
        ),
        "justify-content": (
                responsive: true,
                property: justify-content,
                values: (
                        start: flex-start,
                        end: flex-end,
                        center: center,
                        between: space-between,
                        around: space-around,
                        evenly: space-evenly,
                )
        ),
        "align-items": (
                responsive: true,
                property: align-items,
                values: (
                        start: flex-start,
                        end: flex-end,
                        center: center,
                        baseline: baseline,
                        stretch: stretch,
                )
        ),
        "align-self": (
                responsive: true,
                property: align-self,
                values: (
                        auto: auto,
                        start: flex-start,
                        end: flex-end,
                        center: center,
                        baseline: baseline,
                        stretch: stretch,
                )
        ),
        "text-align": (
                responsive: true,
                property: text-align,
                class: text,
                values: (
                        start: left,
                        end: right,
                        center: center,
                )
        ),
        "flex": (
                responsive: true,
                property: flex,
                values: (fill: 1 1 auto)
        ),
        "order": (
                responsive: true,
                property: order,
                values: (
                        first: -1,
                        0: 0,
                        1: 1,
                        2: 2,
                        3: 3,
                        4: 4,
                        5: 5,
                        last: 6,
                ),
        ),
);


// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {

  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == "map" and (map-get($utility, responsive) or $infix == "") {
        @include generate-utility($utility, $infix);
      }
    }
  }
}


@each $suffix, $brpoint in $media-breakpoints {
  @include media-breakpoint-up($suffix) {

    $min: map-get($media-breakpoints, $suffix);

    @if $min != 0 {
      .flex-#{$suffix}-column {
        flex-direction: column;
      }

      .flex-#{$suffix}-row {
        flex-direction: row;
      }


      @for $i from 1 through 16 {
        $width: 0.25 * $i;

        .gap-#{$suffix}-#{$i} {
          gap: #{$width}rem;
        }
      }

    } @else {

      .flex-column {
        flex-direction: column;
      }

      .flex-row {
        flex-direction: row;
      }

      @for $i from 1 through 16 {
        $width: 0.25 * $i;

        .gap-#{$i} {
          gap: #{$width}rem;
        }
      }
    }

  }
}
