.footer {
  --footer-color: #{$color-text-on-surface-dark};
  --footer-underline-color: rgba(255, 255, 255, .2);

  &.footer--light-bg {
    --footer-color: #{$color-text};
    --footer-underline-color: rgba(0, 0, 0, .2);
  }
}

.footer-text-block {
  display: flex;
  color: var(--footer-color);
  margin-bottom: $space-s;
  font-size: 14px;

  &__i {
    margin-right: $space-s;

    .icon {
      font-size: 18px;
    }
  }

  &__t {
    line-height: 24px;

  }

}


.footer-links {
  margin-bottom: $space-m;
  color: var(--footer-color);
  font-size: 14px;



  &__heading {
    margin-bottom: $space-s;
    font-weight: bold;
    font-size: 16px;
  }

  a {
    color: #ddd;
    display: flex;
    align-items: flex-start;
  }

  a > svg {
    width: 14px;
    height: auto;
    margin-right: 12px;
    margin-top: 4px;
    display: block;
    flex-shrink: 0;
    color: $color-accent;
    transition: all .2s ease-in-out;
  }

  &--horizontal {
    ul {
      width: fit-content;
      margin-left:auto;;
      display: flex;
      align-content: center;
      flex-direction: row;
      gap: $space-m;
    }
  }

  ul li {
    line-height: 22px;
    margin-bottom: 4px
  }

  a:hover {
    color: #fff;

    &>svg {
      transform:translateX(6px);
    }
    //border-bottom: 1px solid var(--footer-underline-color);
  }
}


.footer-social {
  ul {
    display: flex;
    align-items: center;
    gap: $space-xxl;

    .icon {
      font-size: 24px;
    }
  }

  a {
    color: var(--footer-color);
  }

  margin-bottom: $space-m;

}

.footer {
  background-color: #333333 ;

  .buttons {
    margin-bottom: $space-m;
  }
}

