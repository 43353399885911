
.icon {
  height: 1em;
  width: 1em;
  color: inherit;
  display: inline-block;

  &.icon-l {
    width: 1.25em;
    height: 1.25em;
    vertical-align: -.25em;
  }

  &.icon-xl {
    width: 1.5em;
    height: 1.5em;
    vertical-align: -.4em;
  }

  &.icon-xxl {
    width: 2em;
    height: 2em;
    vertical-align: -.675em;
  }
}
