$content-header--hor-space: $space-m;

@mixin content-header{
  margin-top: $content-header--hor-space;
  margin-bottom: $content-header--hor-space;
}

@mixin content-header--title{
  padding-bottom: $space-s;
  border-bottom: 2px solid $color-border;
}

@mixin content-header--h {
  @include media-breakpoint-up('md') {
    margin-top: $space-s;
    font-size: $fontsize-xl;
  }

  margin-top: $space-xs;
  font-size: $fontsize-l;
  font-weight: $fontweight-bold;

  margin-bottom: 0;
}

.content-header {
  @include content-header();

  &__title {
    @include content-header--title();
  }

  &__meta {
    margin-top: $space-l;
    font-size: $fontsize-s;

  }

  &__h {
    @include content-header--h();
  }

}