@mixin cat-tree {
  border: 1px solid $color-surface-highlight;
  font-size: $fontsize-xs;
}

@mixin cat-tree--title {
  background-color: $color-surface-highlight;
  color: $color-text-on-surface-highlight;
  padding: $space-s;
  font-size: $fontsize-s;
  font-weight: $fontweight-bold;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 16px;
    top: 12px;
    width: 10px;
    height: 10px;
    display: block;
    border-right: 2px solid $color-border-extrastrong;
    border-top: 2px solid $color-border-extrastrong;
    transform: rotate(45deg);
  }

  &.expanded {
    &:after {
      transform: rotate(135deg);
    }
  }

  @include media-breakpoint-up('md'){
    &:after {
      content: none;
    }
  }

}

@mixin cat-tree--parent {
  padding: $space-s;
  border-bottom: 1px solid $color-border-light;
  align-items: center;
  display: flex;
  gap: $space-xs;
  color: $color-accent;

  &::before {
    content: '';
    color: transparent;
    width: 0.5rem;
    height: 0.5rem;
    border-left: 1px solid $color-border-strong;
    border-top: 1px solid $color-border-strong;
    transform: rotate(-45deg);
    margin-bottom: .2em;
  }
}

@mixin cat-tree--sublist {
  margin-left: $space-m;
}

@mixin cat-tree--item {

}

@mixin cat-tree--item-selected {

}


@mixin cat-tree--link {
  display: block;
  padding: $space-xs $space-s;
  color: $color-accent;

  &:hover {
    background-color: $color-surface-highlight;
  }
}

@mixin cat-tree--link-selected {
  font-weight: $fontweight-bold;
  color: $color-accent-hover;
}

@mixin cat-tree--subitem {
}

@mixin cat-tree--sublink {
  display: block;
  padding: $space-xs;
  color: $color-accent;

  &:hover {
    background-color: $color-surface-highlight;
  }
}

.cat-tree {
  @include cat-tree();

  &__block-title {
    @include cat-tree--title();
  }


  &__body {
    height: 0;
    overflow: hidden;
  }

  .expanded + &__body {
    height: auto;
  }

  @include media-breakpoint-up('md'){
    &__body {
      height: auto;
      overflow: auto;
    }
  }

  &__parent {
    a {
      display: block;
      @include cat-tree--parent();
    }
  }

  &__item {
    @include cat-tree--item();
  }

  &__item--selected {
    @include cat-tree--item-selected();

    & > .cat-tree__link {
      @include cat-tree--link-selected();
    }
  }

  &__link {
    @include cat-tree--link();
  }

  &__sublist {
    @include cat-tree--sublist();
  }

  &__subitem {
    @include cat-tree--subitem();
  }

  &__sublink {
    @include cat-tree--sublink();
  }

}